<template>
  <div
    class="view-ride"
  >

    <div
      class="page-title-wrapper"
      :class="rideData && !rideData.is_deleted ? 'mb-1' : ''"
    >

      <paginator target="rides">
        Ride
        <template
          v-if="!loading && rideData && rideData.is_deleted"
          #is_deleted
        >
          <div

            class="ride-is-deleted"
          >
            This ride was deleted by user
          </div>
        </template>
      </paginator>

      <actions-dropdown
        :tracked="tracked"
        @deleteRide="deleteRide"
        @exportRide="exportRide"
        @reportBug="reportBug"
      />
    </div>

    <user-card
      v-if="!loading && loaded"
      :key="`user-${rideData.customer.id}`"
      :customer-i-d="rideData.customer.id"
      leading="ride"
      :tracked="tracked"
    />

    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <scooter-card
          :key="rideData.scooter ? `scooter-${rideData.scooter.id}` : 'scooter'"
          :ride-data="rideData"
          :loaded="loaded"
        />
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <settings-card
          :key="`settings-${rideData.id}`"
          :loaded="loaded"
          :ride-data="rideData"
        />

      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <device-card
          :key="`device-${rideData.id}`"
          :loaded="loaded"
          :ride-data="rideData"
        />

      </b-col>
    </b-row>

    <b-row
      v-if="!loading && rideData.errors && rideData.errors.length > 0"
    >
      <b-col
        cols="12"
      >
        <errors-card
          :key="`errors-${rideData.id}`"
          :errors="rideData.errors"
        />
      </b-col>
    </b-row>

    <controllers-widget-card
      :key="`controllers-${rideData.id}`"
      :loaded="loaded"
      :ride-data="rideData"
      :weather-location="pathNormalized[0]"
      :hide-request-button="hideRequestButton"
    />

    <b-row v-if="pathNormalized && pathNormalized.length > 0 && !loading && loaded">
      <b-col>
        <travel-map
          :key="`travelMap-${rideData.id}`"
          class="travel-map"
          :markers="[{id:'start', position: pathNormalized[0], icon: 'start-marker.svg'}, {id:'end', position: pathNormalized && pathNormalized.length > 1 ? pathNormalized[pathNormalized.length - 1] : pathNormalized[0],icon:'end-marker.svg'}]"
          :lines="[{id:'1', path: pathNormalized}]"
        />
      </b-col>
    </b-row>
    <b-card
      v-else-if="loading"
      class="text-center"
    >
      <b-spinner label="Loading..." />
    </b-card>
    <b-row v-if="rideData && tracked > 0 && !loading">
      <b-col>
        <!-- we will add pathNormalized to tracking soon  this was urgent fix oSaMa! -->
        <ride-tracking-chart
          :key="`ridetracking-${rideData.id}`"
          :request-ride-elavation-data-variable="requestRideElavationDataVariable"
          :tracking="rideData.tracking"
          :duration="[rideData.start_time_unix ,rideData.end_time_unix]"
          :show-motor-temprature="showMotorTemprature"
          :ride-i-d="rideData.id"
          :series="series"
          :device-platform="deviceInfo ? deviceInfo : null"
          :elevation-calculated="rideData.is_elevation_calculated == 1 ? true : false"
          @requestRideElavationData="requestRideElavationData"
          @elevationDataLoaded="hideRequestButtonFn"
        />
      </b-col>
    </b-row>
    <b-card
      v-else-if="loading"
      class="text-center"
    >
      <b-spinner label="Loading..." />
    </b-card>
  </div>
</template>
<script>

import {
  BCard, VBTooltip, BCol, BRow, BSpinner,
} from 'bootstrap-vue'
import Moment from 'moment'
import UserCard from '@/views/shared-components/user/UserCard.vue'
import ScooterCard from '@/views/rides/components/ScooterCard.vue'
import SettingsCard from '@/views/rides/components/SettingsCard.vue'
import DeviceCard from '@/views/rides/components/DeviceCard.vue'
import ControllersWidgetCard from '@/views/rides/components/ControllersWidgetCard.vue'
import TravelMap from '@/views/rides/components/Map/TravelMap.vue'
import RideTrackingChart from '@/views/rides/components/RideTrackingChart.vue'
import actionsDropdown from '@/views/rides/components/actionsDropdown.vue'
import paginator from '@/views/shared-components/general/Paginator.vue'

export default {
  components: {
    TravelMap,
    BCard,
    BCol,
    BRow,
    BSpinner,
    UserCard,
    ScooterCard,
    SettingsCard,
    ControllersWidgetCard,
    RideTrackingChart,
    DeviceCard,
    actionsDropdown,
    paginator,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      moment: Moment,
      loading: true,
      loaded: false,
      rideData: {},
      tracked: false,
      requestRideElavationDataVariable: false,
      hideRequestButton: false,
      series: null,
      paginator: null,
      navigationUpdated: false,
    }
  },
  computed: {
    pathNormalized() {
      if (!this.tracked) {
        if (this.rideData.pathNormalized || this.rideData.path) {
          return (this.rideData.pathNormalized || this.rideData.path).coordinates.map(c => ({ lat: c[1], lng: c[0] }))
        }
        return []
      }

      return this.rideData?.tracking?.map(c => ({ lat: c.location.lat, lng: c.location.lng }))

    },
    deviceInfo() {
      return {
        device: this.rideData?.deviceInfo?.device,
        isIOSnew: this.appVersion > 342,
      }
    },
    showMotorTemprature() {
      const { type } = this.rideData.scooter
      return !this.containsAny(type, ['Phantom', 'Ghost'])
    },
    appVersion() {
      if (this.rideData?.app_version || this.rideData?.deviceInfo?.app_version) {
        const appVersion = this.rideData?.app_version ? this.rideData?.app_version.match(/\d/g) : this.rideData?.deviceInfo?.app_version.match(/\d/g)
        return appVersion[0] + appVersion[1] + appVersion[2]
      }
      return 0
    },
  },
  mounted() {
    this.load(this.$route.params.id)
  },
  methods: {
    hideRequestButtonFn() {
      this.hideRequestButton = true
    },
    requestRideElavationData() {
      this.$http.get(this.$resources.rides.requestElevationData.replace(':id', this.$route.params.id)).then(response => {
        const { data: { status } } = response
        if (status === 200) {
          this.requestRideElavationDataVariable = !this.requestRideElavationDataVariable
        }
      })
    },
    exportRide() {
      const fileTitle = `ride-${this.rideData.id}-${this.moment().format('d-m-y-HH:mm:ss')}.csv`
      this.$http.get(this.$resources.rides.export.replace(':id', this.rideData.id), { responseType: 'blob' }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileTitle)
        document.body.appendChild(link)
        link.click()
      })
    },
    deleteRide() {
      this.$swal({
        title: 'Delete Ride?',
        text: 'Would you like to delete this ride?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.value) {
          this.$http.delete(this.$resources.rides.destroy.replace(':id', this.rideData.id), {
            params: {
              reason: 'Admin deleted it',
            },
          }).then(() => {
            this.$router.push({ name: 'rides' })
          })
        }
      })
    },
    reportBug() {
      console.log('report bug')
    },
    load(id) {
      return this.$http.get(this.$resources.rides.fetch.replace(':id', id)).then(response => {
        const { data: { status, data } } = response
        if (status === 200) {
          this.loaded = true
          this.rideData = data.ride
          this.series = data.ride.series
          this.tracked = ((this.rideData.tracking && this.rideData.tracking !== undefined && this.rideData.tracking.length > 0))
          this.loading = false
        }
      })
    },
    upperCaseFirst(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    },
  },
}
</script>
<style lang="scss">
  @import '@/views/rides/scss/viewRide.scss';
</style>
