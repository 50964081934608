<template>
        <b-dropdown
        right
        class="actions-wrapper"
        no-caret
    >
        <b-dropdown-item
            @click="$emit('reportBug')"
        >
        <span
            class="mr-50 bug-icon custom-icon"
        />
            Report bug
        </b-dropdown-item>

    <b-dropdown-item
        v-if="tracked"
        @click="$emit('exportRide')"
    >
        <span
            class="mr-50 export-icon custom-icon"
        />
        
        Export
    </b-dropdown-item>

    <b-dropdown-item @click="$emit('deleteRide')">
        <feather-icon
            size="16"
            icon="Trash2Icon"
            class="mr-50"
        />
            Delete
    </b-dropdown-item>
  </b-dropdown>
</template>
<script>


export default {
    props: {
        tracked: {
            type: Boolean,
            default() {
                return false
            },
        },
    },
}


</script>

<style lang="scss">
 .actions-wrapper {
    display: flex;
    justify-items: right;
      &>button,&>button:focus,>&button:active{
          padding:0px;
          color:#1D1D1D !important;
          background-color: #fff !important;
          background-image: url('~@/assets/images/icons/general-icons/dots-button.png') !important;
          background-repeat: no-repeat !important;
          background-position: center  !important;
          outline: none !important;
      }
      li {
        .custom-icon {
          float: right;
          display: block;
          margin-top: 2px;
          height: 15px;
          width: 15px;
          &.export-icon{
            background-image: url('~@/assets/images/icons/general-icons/export-icon-gray.svg');
          } 
          &.bug-icon{
            height: 16px;
            background-image: url('~@/assets/images/icons/general-icons/bug-icon-gray.svg');
          } 
        }
        &:active {
          a {
            .export-icon {
              background-image: url('~@/assets/images/icons/general-icons/export-icon-white.svg')  !important;
            }
            .bug-icon{
              background-image: url('~@/assets/images/icons/general-icons/bug-icon-white.svg');
            } 
          }
        }
      }
     svg {
      float: right;
     }
     .dropdown-menu {
      position: absolute;
      transform: translate3d(-100px, 51px, 0px);
      top: -4px;
      left: -27px;
      will-change: transform;
      width: 170px;
     }
   }
</style>

