<template>
  <div class="ride-tracking-chart">
    <b-card class="mt-2">

      <b-modal
        id="chart-legend-selector"
        ref="chartLegendSelectorModal"
        title="Chart legend selector"
        size="xs"
        centered
        ok-only
        @hidden="updateChart"
      >
        <b-row class="d-block text-left">
          <b-col>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-checkbox
                v-for="option in options"
                :key="option.value"
                v-model="selectedRideData"
                :value="option.value"
                value-field="value"
                text-field="name"
                :aria-describedby="ariaDescribedby"
                name="flavour-3a"
              >
                {{ option.name }}
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>

      <b-row>
        <b-col>
          <vue-apex-charts
            ref="rideChart"
            :series="selectedSeries"
            :options="chartOptions"
            height="500px"
          />
        </b-col>
      </b-row>
    </b-card>

  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'

export default {
  components: {
    VueApexCharts,
  },
  props: {
    tracking: {
      type: Array,
      require: true,
      default() {
        return []
      },
    },
    rideID: {
      type: String,
      require: true,
      default: null,
    },
    duration: {
      type: Array,
      require: true,
      default() {
        return []
      },
    },
    showMotorTemprature: {
      type: Boolean,
      default() {
        return true
      },
    },
    devicePlatform: {
      type: Object,
      default() {
        return null
      },
    },
    requestRideElavationData: {
      type: Boolean,
      default() {
        return false
      },
    },
    requestRideElavationDataVariable: {
      type: Boolean,
      default() {
        return false
      },
    },
    elevationCalculated: {
      type: Boolean,
      default() {
        return false
      },
    },
    series: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      rideTimeStamps: [],
      fisrtLoad: true,
      loading: true,
      requstElevationDataBtn: true,
      selectedSeries: [],
      selectedRideData: ['Gear', 'Power', 'Speed', 'Current', 'Voltage', 'Battery Percentage', 'Motor Temperature', 'Controller Temperature',
        'Battery Temperature', 'Battery Capacity',
      ],
      options: [
        { value: 'Gear', name: 'Gear' },
        { value: 'Power', name: 'Power' },
        { value: 'Speed', name: 'Speed' },
        { value: 'Current', name: 'Current' },
        { value: 'Voltage', name: 'Voltage' },
        { value: 'Battery Percentage', name: 'Battery Percentage' },
        { value: 'Motor Temperature', name: 'Motor Temperature' },
        { value: 'Controller Temperature', name: 'Controller Temperature' },
        { value: 'Battery Temperature', name: 'Battery Temperature' },
        // { value: 'Distance', name: 'Distance' },
        // { value: 'Location', name: 'Location' },
        { value: 'Battery Capacity', name: 'Battery Capacity' },
      ],
      chartOptions: {
        tracking: this.tracking,
        chart: {
          animations: {
            enabled: false,
          },
          type: 'line',
          stacked: false,
          height: 600,
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            autoSelected: 'zoom',
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: true,
              customIcons: [
                {
                  icon: '',
                  index: 1,
                  title: 'Legend selector',
                  class: 'custom-legend-list-icon',
                  click: () => {
                    this.$refs.chartLegendSelectorModal.show()
                  },
                },
                {
                  icon: '',
                  index: -4,
                  title: 'Elevation',
                  class: 'request-ride-elavation-data-wrapper',
                  click: () => {
                    if (!this.elevationCalculated) {
                      this.getRideElavationData()
                    } else {
                      this.$notify({ type: 'error', title: 'Service not available', text: 'Elevation data already requested' })
                    }
                    this.requstElevationDataBtn = false
                  },
                },
              ],
            },
          },
          events: {
            legendClick: async (chartContext, seriesIndex, config) => {
              const s = config.config.series[seriesIndex]
              if (s.name === 'Power') {
                if (s.data.length > 0) {
                  await this.regenerateYaxis(['Power'])
                } else {
                  await this.regenerateYaxis([])
                }
              }
            },
            mounted: () => {
              if (!this.elevationCalculated) {
                const powerSeries = this.series.filter(s => s.name === 'Power')[0]
                const gearSeries = this.series.filter(s => s.name === 'Gear')[0]
                if ((powerSeries || gearSeries) && this.fisrtLoad) {
                  this.fisrtLoad = false
                  setTimeout(() => {
                    if (powerSeries) {
                      this.$refs.rideChart.hideSeries('Power')
                    }
                    if (gearSeries) {
                      this.$refs.rideChart.hideSeries('Gear')
                    }
                  }, 500)
                }
              }
            },
            mouseMove: (event, chartContext, opts) => {
              if (opts.config.tracking) {
                let location = opts.config.tracking[opts.dataPointIndex] ? opts.config.tracking[opts.dataPointIndex].location : null
                if (location) {
                  if (this.devicePlatform.device === 'Android') {
                    location = {
                      lat: location.lat ? location.lat : location.latitude,
                      lng: location.lng ? location.lng : location.longitude,
                    }
                  }

                  if (this.devicePlatform.device === 'IOS') {
                    if (this.devicePlatform.isIOSnew) {
                      location = {
                        lat: location.lat ? location.lat : location.latitude,
                        lng: location.lng ? location.lng : location.longitude,
                      }
                    } else {
                      location = {
                        lat: location.lng ? location.lng : location.longitude,
                        lng: location.lat ? location.lat : location.latitude,
                      }
                    }
                  }

                  if (this.devicePlatform.device === null) {
                    location = {
                      lat: location.lat ? location.lat : location.latitude,
                      lng: location.lng ? location.lng : location.longitude,
                    }
                  }
                  this.showMarker(location)
                }
              }
            },

          },

        },
        legend: {
          show: true,
          position: 'top',
          floating: false,
          verticalAlign: 'top',
          horizontalAlign: 'left',
        },
        zoom: {
          enabled: true,
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
        },
        stroke: {
          curve: 'smooth',
          width: 3,
        },
        title: {
          text: 'Ride Tracking Chart',
          align: 'left',
        },
        fill: {
          gradient: {
            type: 'vertical',
          },
        },
        yaxis:
        {
          decimalsInFloat: 0,
          title: {
            text: 'Ride Data',
          },
        },
        xaxis: {
          type: 'datetime',
          axisBorder: {
            show: true,
          },
          axisTicks: {
            show: true,
          },
          labels: {
            formatter: val => moment(val).format('h:mma'),
          },
        },
        tooltip: {
          y: {
            formatter(val) {
              return val ? (val).toFixed(0) : 0
            },
          },
        },
      },
      mapPathMarker: null,
    }
  },
  watch: {
    requestRideElavationDataVariable() {
      document.location.reload()
    },
    async selectedRideData() {
      await this.regenerateData()
    },
  },
  async mounted() {
    if (this.elevationCalculated) {
      this.options.push({ value: 'Elevation', name: 'Elevation' })
      this.selectedRideData.push('Elevation')
    }
    await this.regenerateData()
  },
  methods: {
    regenerateYaxis(hideSeries) {
      const minmax = this.selectedSeries.filter(s => s.name !== 'Elevation' && !hideSeries.includes(s.name)).map(s => ({ min: s.min, max: s.max }))
      const min = this.$getMinAndMax(minmax, 'min')
      const max = this.$getMinAndMax(minmax, 'max')

      const yaxis = [
        {
          decimalsInFloat: 0,
          title: {
            text: 'Ride Data',
          },
          min: min.min,
          max: max.max,
        },
      ]

      const elevationIndex = this.selectedSeries.map(s => s.name).indexOf('Elevation')
      if (this.elevationCalculated && elevationIndex > -1) {
        yaxis.push(
          {
            // seriesName: 'Elevation',
            opposite: true,
            max: this.selectedSeries[elevationIndex].max + 10,
            min: this.selectedSeries[elevationIndex].min - 10,
            decimalsInFloat: 0,
            axisTicks: {
              show: true,
            },
            title: {
              text: 'Elevation',
            },
            tooltip: {
              enabled: true,
            },
          },
        )

        setTimeout(() => {
          const request = document.getElementsByClassName('request-ride-elavation-data-wrapper')[0]
          if (typeof request !== 'undefined') {
            request.classList.add('request-ride-elavation-data-wrapper-grayout')
          }
        }, 1000)
      }

      this.$refs.rideChart.updateOptions({
        yaxis,
      })
    },
    regenerateData() {
      this.selectedSeries = this.series.filter(s => this.selectedRideData.includes(s.name))

      this.regenerateYaxis(['Power'])
    },
    async getRideElavationData() {
      const request = document.getElementsByClassName('request-ride-elavation-data-wrapper')[0]

      if (typeof request !== 'undefined') {
        request.classList.add('request-ride-elavation-data-wrapper-loader')
      }
      this.$emit('requestRideElavationData')
    },
    generateColors() {
      return this.colorsData.map((d, idx) => {
        const color = d > 0 ? '#22c55f' : '#ef4544'
        return {
          offset: (idx / this.colorsData.length) * 120,
          color,
          opacity: 1,
        }
      })
    },
    updateChart() {
      this.$refs.rideChart.toggleSeries('Power')
    },
    showMarker(location) {
      const { Marker } = this.$googleMap.maps
      if (this.mapPathMarker) {
        this.mapPathMarker.setPosition(location)
      } else {
        const markerIcon = require('@/assets/images/icons/ride/start-marker.svg')
        this.mapPathMarker = new Marker({
          position: location,
          map: this.$globalGoogleMap,
          icon: {
            url: markerIcon,
            size: new this.$googleMap.maps.Size(30, 30),
            scaledSize: new this.$googleMap.maps.Size(25, 25),
            anchor: new this.$googleMap.maps.Point(10, 10),
          },
        })
      }
    },

  },
}
</script>

<style lang="scss">
.ride-tracking-chart-ride-options {
  display: inline-flex;
  .custom-checkbox {
    flex: 10;
  }
}
.custom-legend-list-icon {
  background-image: url('~@/assets/images/icons/ride/selector-icon.svg') !important;
  background-size: 100% !important;
  background-repeat: no-repeat;
  &:hover {
    background-image: url('~@/assets/images/icons/ride/selector-icon-black.svg') !important;
  }
}

.request-ride-elavation-data-wrapper {
    background-image: url('~@/assets/images/icons/ride/elevation-icon.svg');
    background-size: 100% !important;
    background-repeat: no-repeat;
    width: 33px !important;
    height: 26px !important;

    &.request-ride-elavation-data-wrapper-grayout {
      background-image: url('~@/assets/images/icons/ride/elevation-icon-gray.svg');
    }
    &.request-ride-elavation-data-wrapper-loader {
      background-image: url('~@/assets/images/icons/ride/loader.gif');
    }
  }

.apexcharts-legend {
  padding-left: 21px !important;
  .apexcharts-legend-series {
    margin-right: 15px !important;
  }
}

[seriesName="Elevation"] {
  /* .apexcharts-legend-marker {
      color :#D3C9C6 !important;
      background: #D3C9C6  !important;
  } */
  &.apexcharts-series {
    opacity: 0.3;
  }
}
</style>
