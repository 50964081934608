<template>
  <b-row class="controllers-widget">
    <b-col
      cols="12"
      lg="3"
      md="4"
      class="widget"
    >
      <b-card v-if="loaded">
        <b-row class="no-spaces">
          <label class="heading-text black-text bold-text"><img
            class="mr-1-5"
            src="@/assets/images/icons/ride/temp.png"
          >Temperature</label>
        </b-row>
        <b-row class="mt-1 no-spaces">
          <div class="label-text gray-text col-12 no-padding">
            Controller Temperature
          </div>
          <div
            class="degree"
            :class="returnClass(rideData.max_controller_temperature)+'-text'"
          >
            <span class="number-area">{{ rideData.max_controller_temperature || '0' }}</span>
            <span class="empty-space" />
            <span class="sign-text-small">&#176;C</span>
            <div
              v-if="rideData.max_controller_temperature > 75 "
              class="float-right danger-sign"
            >
              <img src="@/assets/images/icons/ride/danger.png">
            </div>
          </div>
          <span
            class="indicator mt-1"
            :class="returnClass(rideData.max_controller_temperature)+'-indicator'"
          >
            <span
              class="indicator"
              :class="returnClass(rideData.max_controller_temperature)+'-indicator-progress'"
              :style="'width:'+widthValue(rideData.max_controller_temperature)"
            />
          </span>
        </b-row>

        <b-row
          class="mt-3 no-width-spaces"
        >
          <div class="label-text gray-text col-12 no-padding">
            Motor Temperature
          </div>
          <div
            v-if="showMotorTemprature"
            class="degree"
            :class="returnClass(rideData.max_motor_temperature)+'-text'"
          >
            <span class="number-area">{{ rideData.max_motor_temperature || '0' }}</span>
            <span class="empty-space" />
            <span class="sign-text-small">&#176;C</span>
            <div
              v-if="rideData.max_motor_temperature > 75 "
              class="float-right danger-sign"
            >
              <img src="@/assets/images/icons/ride/danger.png">
            </div>
          </div>
          <span
            v-if="showMotorTemprature"
            class="indicator"
            :class="returnClass(rideData.max_motor_temperature)+'-indicator'"
          >
            <span
              class="indicator"
              :class="returnClass(rideData.max_motor_temperature)+'-indicator-progress'"
              :style="'width:'+widthValue(rideData.max_motor_temperature)"
            />
          </span>

          <span
            v-else
            class="degree safe-text number-area"
          >
            N/A
          </span>
        </b-row>
      </b-card>
      <div
        v-else
        class="widget-loader-wrapper"
      >
        <widget-loader />
      </div>

    </b-col>
    <b-col
      cols="12"
      lg="3"
      md="4"
      class="widget"
    >
      <b-card v-if="loaded">
        <b-row class="pb-1 no-spaces">
          <label class="heading-text black-text bold-text"><img
            class="mr-1-5"
            src="@/assets/images/icons/ride/time.png"
          >Ride</label>
          <!-- <span
            v-if="rideData.is_deleted"
            class="ml-1"
          >
            <img
              v-b-tooltip="'This ride was deleted by user'"
              src="@/assets/images/icons/ride/deleted-ride.svg"
            >
          </span> -->

        </b-row>
        <b-row>
          <b-col class="label-text gray-text  mb-1">
            <span class="time-title">Start time</span>
            <b-col class="timer-text">
              <b-row>{{ moment.utc(rideData.start_time).local().format('hh:mm') }} <span class="sign-text">{{ moment.utc(rideData.start_time).local().format('a') }}</span></b-row>
              <b-row>{{ moment.utc(rideData.start_time).local().format('YY.MM.DD') }}</b-row>
            </b-col>
          </b-col>
          <b-col class="label-text gray-text  mb-1">
            <span class="time-title">End time</span>
            <b-col class="timer-text">
              <b-row>{{ moment.utc(rideData.end_time).local().format('hh:mm') }} <span class="sign-text">{{ moment.utc(rideData.end_time).local().format('a') }}</span></b-row>
              <b-row>{{ moment.utc(rideData.end_time).local().format('YY.MM.DD') }}</b-row>
            </b-col>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col class="label-text gray-text  mb-1">
            <span class="time-title margin-b-5">Duration</span>
            <b-col class="timer-text">
              <b-row>{{ rideTime || '-' }}<span class="sign-text">min</span></b-row>
            </b-col>
          </b-col>
          <b-col>
            <span class="time-title margin-b-5">Distance</span>
            <b-col class="timer-text">
              <b-row>{{ Math.round(rideData.distance * 100) / 100 || '-' }}<span class="sign-text">km</span></b-row>
            </b-col>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <span class="time-title margin-b-5">Country</span>
            <b-col class="timer-text">
              <b-row>{{ rideData.country || '-' }}</b-row>
            </b-col>
          </b-col>

          <b-col class="weather">
            <span class="time-title margin-b-5">Weather</span>
            <b-col class="timer-text">
              <b-row>{{ rideWeather || '-' }}
                <img
                  v-if="rideWeather"
                  width="35px"
                  height="35px"
                  :src="rideWeatherIcon"
                >
              </b-row>

            </b-col>
          </b-col>

        </b-row>

        <!-- <b-row>
          <b-col>
            <span class="time-title margin-b-5">Elevation</span>
            <b-col class="timer-text">
              <b-row>
                <button
                  v-if="showRequestButton && !this.rideData.is_elevation_calculated"
                  class="btn btn-outline-secondary request-btn"
                  @click="requestElavationData"
                >
                  Request
                </button>
                <div
                  v-else
                  class="requested-text"
                >
                  Requested
                </div>
              </b-row>
            </b-col>
          </b-col>

        </b-row> -->

      </b-card>
      <div
        v-else
        class="widget-loader-wrapper"
      >
        <widget-loader />
      </div>
    </b-col>
    <b-col
      cols="12"
      lg="3"
      md="4"
      class="widget"
    >
      <b-card v-if="loaded">
        <b-row class="no-spaces">
          <label class="no-spaces heading-text black-text bold-text"><img
            class="mr-1-5"
            src="@/assets/images/icons/ride/speed.png"
          >Speed</label>
        </b-row>
        <b-row class="no-width-spaces">
          <div class="label-text gray-text">
            Max Speed
          </div>
          <div
            class="degree"
            :class="returnClass(maxSpeed)+'-text'"
          >
            <span class="number-area">{{ maxSpeed || '0' }}</span>
            <span class="empty-space" />
            <span class="sign-text-small">km/h</span>
            <div
              v-if="maxSpeed > 75 "
              class="float-right danger-sign"
            >
              <img src="@/assets/images/icons/ride/danger.png">
            </div>
          </div>
          <span
            class="indicator"
            :class="returnClass(maxSpeed)+'-indicator'"
          >
            <span
              class="indicator"
              :class="returnClass(maxSpeed)+'-indicator-progress'"
              :style="'width:'+widthValue(maxSpeed)"
            />
          </span>
        </b-row>
        <!-- avgSpeed -->
        <b-row class="mt-3 no-width-spaces">
          <div class="label-text gray-text">
            Average Speed
          </div>
          <div
            class="degree"
            :class="returnClass(averageSpeed)+'-text'"
          >
            <span class="number-area">{{ averageSpeed || '0' }}</span>
            <span class="empty-space" />
            <span class="sign-text-small">km/h</span>

            <div
              v-if="false"
              class="float-right danger-sign"
            >
              <img src="@/assets/images/icons/ride/danger.png">
            </div>
          </div>
          <span
            class="indicator"
            :class="returnClass(averageSpeed)+'-indicator'"
          >
            <span
              class="indicator"
              :class="returnClass(averageSpeed)+'-indicator-progress'"
              :style="'width:'+widthValue(averageSpeed)"
            />
          </span>

        </b-row>
      </b-card>
      <div
        v-else
        class="widget-loader-wrapper"
      >
        <widget-loader />
      </div>

    </b-col>
    <b-col
      cols="12"
      lg="3"
      md="4"
      class="widget"
    >
      <b-card v-if="loaded">
        <b-row class="pb-1 no-spaces">
          <label class="heading-text black-text bold-text"><img
            class="mr-1-5"
            src="@/assets/images/icons/ride/battery.png"
          >Battery</label>
        </b-row>

        <div class="no-spaces battery-consumption-class">
          <div class="label-text gray-text col-12 no-padding">
            Battery Consumption
          </div>
          <b-row v-if="rideData.tracking && rideData.tracking.length > 0">
          <div class="percentage-indicator-wrapper">
            <div class="percentage-indicator">
              <div class="value start-value" :style="`height:${batteryfromTwoPercentageHealth.startPercentageValue}%;`"></div>
              <div class="value end-value" :style="`height:${batteryfromTwoPercentageHealth.endPercentageValue}%;`"></div>
            </div>
          </div>
          <b-col cols="10">
            <div class="sign-text large-sign-text">
              {{ parseInt(rideData.battery_consumption).toFixed(0) || '0' }}
              <span class="empty-space" />
              <span class="sign-percentage-sign">%</span>
            </div>
            <div
             
              class="from-two-percentage"
            >
              {{ batteryfromTwoPercentageHealth.percentageText }} <span class="battery-capacity float-right">{{ batteryCapacityInmAh ?  batteryCapacityInmAh : ''}}</span>
            </div>
          </b-col>

        </b-row>
          <div
            v-else
            class="from-two-percentage no-data"
          >
            No data
          </div>
        </div>
        <b-row class="mt-2 no-width-spaces-2">
          <div class="label-text gray-text col-12 no-padding">
            Battery Health
          </div>
          <div
            class="degree"
            :class="batteryHealthClass+'-text'"
          >
            <span class="number-area">{{ batteryHealth }}</span>
            <span class="empty-space" />
            <span class="sign-text-small">wh/km
              <span
              v-if="batteryHealthClass == 'danger' "
              class="float-right danger-sign capacity-danger-sign"
            >
              <img src="@/assets/images/icons/ride/danger.png">
            </span>
            </span>
          </div>
          <span
            class="indicator"
            :class="batteryHealthClass+'-indicator'"
          >
            <span
              class="indicator"
              :class="batteryHealthClass+'-indicator-progress'"
              :style="'width:'+widthValue(batteryHealth)"
            />
          </span>
        </b-row>
      </b-card>
      <div
        v-else
        class="widget-loader-wrapper"
      >
        <widget-loader />
      </div>

    </b-col>
  </b-row>
</template>
<script>
import Moment from 'moment'
import WidgetLoader from '@/views/components/WidgetLoader.vue'

export default {
  components: {
    WidgetLoader,
  },
  props: {
    rideData: {
      type: Object,
      default() {
        return null
      },
    },
    loaded: {
      type: Boolean,
      default() {
        return false
      },
    },
    weatherLocation: {
      type: Object,
      default() {
        return null
      },
    },
    hideRequestButton: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      moment: Moment,
      scooter_fixed_battery_watt: {
        'Apollo Mini': 280,
        'Apollo Air': 280,
        'Apollo Air Pro': 360,
        'Apollo Air Pro 2022': 540,
        'Apollo City': 633,
        'Apollo City Pro': 873,
        'Phantom': 1196,
        'Phantom Pro': 1560,
        'Apollo One': 1560,
         unknown: 700,
      },
      scooter_fixed_battery_capacities: {
        'Apollo Mini': 280,
        'Apollo Air': 7600,
        'Apollo Air Pro': 10400,
        'Apollo Air Pro 2022': 15000,
        'Apollo City': 13500,
        'Apollo City Pro': 18000,
        'Apollo Phantom': 23400,
        'Phantom Pro': 23400,
        'Apollo One': 30000,
        'Apollo pro': 30000,
         unknown: null,
      },


      rideWeather: 0,
      rideWeatherIcon: '',
      showRequestButton: true,
    }
  },
  computed: {
    showMotorTemprature() {
      const { type } = this.rideData.scooter
      return !this.containsAny(type, ['Phantom', 'Ghost'])
    },
    batteryfromTwoPercentageHealth() {
      const startPercentage = this.rideData.tracking[0].batteryP
      const endPercentage = this.rideData.tracking[this.rideData.tracking.length - 1].batteryP
      const batteryObject = {
        startPercentageValue: startPercentage,
        endPercentageValue: endPercentage,
        percentageText: `${startPercentage}% to ${endPercentage}%`
      }
      return batteryObject
    },
    rideTime() {
      if (this.rideData.start_time_unix && this.rideData.end_time_unix) {
        const start = Moment.unix(this.rideData.start_time_unix)
        const end = Moment.unix(this.rideData.end_time_unix)
        const duration = end.diff(start, 'minutes')
        return `${duration}`
      }
      return '-'
    },

    averageSpeed() {
      if (this.rideData.avg_speed) {
        return this.rideData.avg_speed
      }

      const start = Moment.unix(this.rideData.start_time_unix)
      const end = Moment.unix(this.rideData.end_time_unix)
      const time = end.diff(start, 'minutes')

      const { distance } = this.rideData

      const avgSpeed = (distance / time) * 60
      return avgSpeed.toFixed(2)
    },
    maxSpeed() {
      if (this.rideData.max_speed) {
        return this.rideData.max_speed
      }

      return this.averageSpeed
    },

    batteryHealth() {
      const batteryWatt = this.scooter_fixed_battery_watt[this.rideData.scooter.type]
        ? this.scooter_fixed_battery_watt[this.rideData.scooter.type] : this.scooter_fixed_battery_watt.unknown
      return ((batteryWatt * (this.rideData.battery_consumption / 100)) / this.rideData.distance).toFixed(2)
    },

    batteryCapacityInmAh() {
      const batteryCapacity = this.scooter_fixed_battery_capacities[this.rideData.scooter.type]
        ? this.scooter_fixed_battery_capacities[this.rideData.scooter.type] : this.scooter_fixed_battery_capacities.unknown
      return batteryCapacity ? `${((batteryCapacity * this.rideData.battery_consumption) / 100).toFixed(0)} mAh` : ''
    },


    batteryHealthClass() {
      const { batteryHealth } = this
      const { scooter: { type } } = this.rideData
      let healthClass
      switch (type) {
        case 'Apollo Air':
        case 'Mini':
          if (batteryHealth <= 18 || batteryHealth === undefined) {
            healthClass = 'safe'
          } else if (batteryHealth > 18 && batteryHealth <= 28) {
            healthClass = 'warning'
          } else if (batteryHealth > 28) {
            healthClass = 'danger'
          }
          break

        case 'Apollo Air Pro':
          if (batteryHealth <= 20 || batteryHealth === undefined) {
            healthClass = 'safe'
          } else if (batteryHealth > 20 && batteryHealth <= 25) {
            healthClass = 'warning'
          } else if (batteryHealth > 25) {
            healthClass = 'danger'
          }
          break

        case 'Apollo Air Pro 2022':
          if (batteryHealth <= 26 || batteryHealth === undefined) {
            healthClass = 'safe'
          } else if (batteryHealth > 27 && batteryHealth <= 33) {
            healthClass = 'warning'
          } else if (batteryHealth > 33) {
            healthClass = 'danger'
          }
          break

        case 'Apollo City':
          if (batteryHealth <= 25 || batteryHealth === undefined) {
            healthClass = 'safe'
          } else if (batteryHealth > 25 && batteryHealth <= 42.2) {
            healthClass = 'warning'
          } else if (batteryHealth > 42.2) {
            healthClass = 'danger'
          }
          break

        case 'Apollo City Pro':
          if (batteryHealth <= 33 || batteryHealth === undefined) {
            healthClass = 'safe'
          } else if (batteryHealth > 33 && batteryHealth <= 40) {
            healthClass = 'warning'
          } else if (batteryHealth > 40) {
            healthClass = 'danger'
          }
          break

        case 'Apollo Phantom':
          if (batteryHealth <= 30 || batteryHealth === undefined) {
            healthClass = 'safe'
          } else if (batteryHealth > 30 && batteryHealth <= 40) {
            healthClass = 'warning'
          } else if (batteryHealth > 40) {
            healthClass = 'danger'
          }
          break

        case 'Apollo Phantom Pro':
          if (batteryHealth <= 39 || batteryHealth === undefined) {
            healthClass = 'safe'
          } else if (batteryHealth > 39 && batteryHealth <= 52) {
            healthClass = 'warning'
          } else if (batteryHealth > 52) {
            healthClass = 'danger'
          }
          break

        default:
          if (batteryHealth <= 60 || batteryHealth === undefined) {
            healthClass = 'safe'
          } else if (batteryHealth > 60 && batteryHealth <= 75) {
            healthClass = 'warning'
          } else {
            healthClass = 'danger'
          }
      }
      return healthClass
    },

  },
  watch: {
    rideData: {
      handler() {
        this.getRideWeather()
      },
      deep: true,
      immediate: true,
    },
    hideRequestButton: {
      handler() {
        this.showRequestButton = false
      },
    },
  },
  methods: {
    requestElavationData() {
      this.$emit('requestRideElavationData')
    },
    getRideWeather() {
      const d = new Date((this.rideData.start_time_unix / 1000))
      const hour = d.getHours()

      if(this.weatherLocation) {
        const { lat, lng } = this.weatherLocation
        this.$http.get(`https://api.weatherapi.com/v1/forecast.json?key=9956afcaf461405baf6204053231207&q=${lat.toFixed(2)},${lng.toFixed(2)}&hour=${hour}`).then(response => {
          const { data: { forecast } } = response
          const { day } = forecast.forecastday[0]
          if (day) {
            this.rideWeather = day.avgtemp_c ? `${day.avgtemp_c}°C` : null
            this.rideWeatherIcon = day.condition.icon
          } else {
            this.rideWeather = null
            this.rideWeatherIcon = null
          }
        })
      }

    },
    returnClass(degree) {
      if (degree <= 60 || degree === undefined) {
        return 'safe'
      } if (degree > 60 && degree <= 75) {
        return 'warning'
      }
      return 'danger'
    },
    widthValue(value) {
      return value < 100 ? `${value}%` : '100%'
    },
  },
}
</script>

<style lang="scss">
.capacity-danger-sign {
  margin-top: -5px;
}
.requested-text {
  font-size: 1.3rem;
}
.weather {
  img {
    margin-top: -10px;
  }
}
.battery-consumption-class {
  .from-two-percentage{
    font-family: Montserrat;
    font-weight: 700;
    font-size: 22px;
    height: 28px;
    color: black;
    font-style: normal;
    margin-top: 10px;
    width: 100%;
    line-height: 18px;
    
    .battery-capacity {
      font-size: 16px;
      font-weight: 600;
      color: #A19590;
      line-height: 20px;
    }
  }
  .large-sign-text {
    margin-left: 0;
  }

  .percentage-indicator-wrapper {
      width: 15px;
      margin-left: 15px;
    .percentage-indicator {
        height: 100% !important;
        border: 0.5px solid #D3C9C6;
        border-radius: 10px;
        position: relative;
        .value {
          position: absolute;
          bottom: 0;
          width: 100%;
          border-radius: 10px;
          &.start-value {
            background-color: #A7E7B1;
            border-radius: 0;
          }
          &.end-value {
            background-color: #24C23C;
            z-index: 1;
            border-radius: 0 0 10px 10px;
          }

        }
  }

  }


}

.battery-consumption-class .from-two-percentage.no-data {
  font-size: 18px;
  margin-top: 18px;
  color: #C6C6C6;
}

.widget-loader-wrapper {
  .card {
    .card-body {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width: 1680px) {
  .battery-consumption-class .from-two-percentage{
    margin-left: 0px;
    width:100%;
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .battery-consumption-class .from-two-percentage{
    margin-left: 0 !important;
    width:50% !important;
    display: inline !important;
    margin-top: 10px;
  }
}

@media screen and (max-width: 319px) {
  .battery-consumption-class .from-two-percentage{
    width:100% !important;
    display: block !important;
    margin-top: 10px;
    margin-left: 0px !important;
  }
}
</style>
