<template>
  <b-card
    v-if="!loaded"
    class="widget-loader-wrapper"
  >
    <widget-loader />
  </b-card>

  <b-card
    v-else
    class="settings-card"
  >
    <h1 class="heading-text black-text bold-text">
      Settings
    </h1>
    <b-row>
      <b-col>
        <ul class="list-unstyled">
          <li class="data-line">
            <label class="label">Top Speed Limit Set:</label> {{ rideData.top_speed_limit_set || '0' }}
          </li>
          <li class="data-line">
            <label class="label">Acceleration Set:</label> {{ rideData.acceleration_set || '0' }}
          </li>
          <li class="data-line">
            <label class="label">Regenerative Set:</label> {{ rideData.regen_set || '0' }}
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import WidgetLoader from '@/views/components/WidgetLoader.vue'

export default {
  components: {
    WidgetLoader,
  },
  props: {
    rideData: {
      type: Object,
      default() {
        return null
      },
    },
    loaded: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.card-body {
    height: 230px;
}
.data-line {
  margin-bottom: 5px;
  .label {
    font-weight: bold;
    font-size: 15px;
    color: #000;
    margin-right: 5px;
  }
  .link {
    cursor: pointer;
  }
}
</style>
