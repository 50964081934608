<template>
  <b-card
    v-if="!loaded"
    class="widget-loader-wrapper"
  >
    <widget-loader />
  </b-card>
  <b-card
    v-else
    class="scooter-card"
  >
    <h1 class="heading-text black-text bold-text">
      Scooter
    </h1>
    <b-row>
      <b-col>
        <ul class="list-unstyled">
          <li class="data-line">
            <label class="label">Model:</label>{{ rideData.scooter.type || '-' }}
          </li>
          <li class="data-line">
            <label
              v-b-tooltip="'Goto Scooter Page'"
              class="label link"
              @click="() => $ableTo('View Scooters') && $router.push({name: 'view-scooter', params: { id: rideData.scooter.id } })"
            >Name:</label>{{ rideData.scooter.name || '-' }}
          </li>

          <li class="data-line">
            <label
              v-b-tooltip="'Goto Scooter Page'"
              class="label link"
              @click="() => $ableTo('View Scooters') && $router.push({name: 'view-scooter', params: { id: rideData.scooter.id } })"
            >
              Serial:</label>
            {{ (rideData.scooter.serial || '-|').split('|')[0] }}
          </li>

          <li class="data-line">
            <label
              class="label"
            >
              Odometer:
            </label>
            {{ Math.round(rideData.odometer * 100) / 100 + 'Km' || '0' }}
          </li>


          <li class="data-line">
            <label
              class="label"
            >
             Weight limit:
            </label>
            {{ bracketWight }}
            <div
              v-if="overWeight"
              id="weight-bracket"
              class="float-right danger-sign-scooter"
            >
              <img src="@/assets/images/icons/ride/danger-outline.png">
            </div>

              <b-tooltip target="weight-bracket" placement="top">
                <div class="text-left font-weight-bolder">Customer’s weight is out of the scooter range {{ customerWeight }}</div>
              </b-tooltip>

          </li>

          <li class="data-line">
            <label
                class="label"
            >
              Current Owner:
            </label>
            {{ (rideData.scooter.owner && rideData.scooter.owner.name) || '-' }}
          </li>

        </ul>
      </b-col>

    </b-row>
  </b-card>
</template>
<script>
import WidgetLoader from '@/views/components/WidgetLoader.vue'

export default {
  components: {
    WidgetLoader,
  },
  props: {
    rideData: {
      type: Object,
      default() {
        return null
      },
    },
    loaded: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  computed: {
    bracketWight() {
      return this.scooter_weight_limits[this.rideData.scooter.type] ? `${this.scooter_weight_limits[this.rideData.scooter.type]} lb` : `${this.scooter_weight_limits.unknown} lb`;
    },

    overWeight() {
      return (this.rideData.customer?.weight?.value && this.rideData.customer?.weight?.unit > (this.scooter_weight_limits[this.rideData.scooter.type] ? this.scooter_weight_limits[this.rideData.scooter.type] : this.scooter_weight_limits.unknown))
    },
    customerWeight() {
      return (this.rideData.customer?.weight?.value &&  this.rideData.customer?.weight?.unit) ? `${this.rideData.customer.weight.value} ${this.rideData.customer.weight.unit}` : '-'
    }
  },
  data(){
    return {
      scooter_weight_limits: {
        'Apollo City Pro': 260, //
        'Apollo Air Pro 2022': 220, //
        'Apollo Phantom': 260, //
        'Apollo Air Pro': 220, //
        'Apollo City Pro 2023': 260, //
        'Apollo Phantom 2023': 260, //
        'Apollo City': 220, //
        'Apollo Air': 220, //
        'Apollo Air Pro 2023': 220, //
        'Apollo Pro 2023': 280, //
        'Apollo City BB 2023': 220, //,
        'Apollo Ghost': 260, //
        'Unknown': 220,
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.danger-sign-scooter {
    position: absolute;
    right: 5px;
    bottom: 5px;
}

.card-body {
    height: 250px;
}
.data-line {
  margin-bottom: 5px;
  .label {
    font-weight: bold;
    font-size: 15px;
    color: #000;
    margin-right: 5px;
  }
  .link {
    cursor: pointer;
  }
}
</style>
