<template>
  <b-card
    v-if="!loaded"
    class="widget-loader-wrapper"
  >
    <widget-loader />
  </b-card>
  <b-card
    v-else
    class="device-card"
  >
    <h1 class="heading-text black-text bold-text">
      Device
    </h1>
    <b-row>
      <b-col>
        <ul class="list-unstyled">
          <li class="data-line">
            <label class="label">Phone:</label>{{ AppPhone || '-' }}
          </li>
          <li class="data-line">
            <label class="label">App:</label>{{ AppVersion || '-' }}
          </li>
          <li class="data-line">
            <label class="label">SDK:</label>{{ AppSDK || '-' }}
          </li>
          <li class="data-line">
            <label class="label">Device:</label>{{ AppOs || '-' }}
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import WidgetLoader from '@/views/components/WidgetLoader.vue'

export default {
  components: {
    WidgetLoader,
  },
  props: {
    rideData: {
      type: Object,
      default() {
        return null
      },
    },
    loaded: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  computed: {
    AppPhone() {
      let appPhone
      if (this.rideData.app_phone) {
        appPhone = this.rideData.app_phone
      } else if (this.rideData.deviceInfo && this.rideData.deviceInfo.model) {
        appPhone = this.rideData.deviceInfo.model
      }
      return appPhone
    },
    AppVersion() {
      let appVersion
      if (this.rideData.app_version) {
        appVersion = this.rideData.app_version
      } else if (this.rideData.deviceInfo && this.rideData.deviceInfo.model) {
        appVersion = this.rideData.deviceInfo.app_version
      }
      return appVersion
    },
    AppSDK() {
      let appSDK
      if (this.rideData.app_sdk) {
        appSDK = this.rideData.app_sdk
      } else if (this.rideData.deviceInfo && this.rideData.deviceInfo.sdk) {
        appSDK = this.rideData.deviceInfo.sdk
      }
      return appSDK
    },
    AppOs() {
      let appOs
      if (this.rideData.app_os) {
        appOs = this.rideData.app_os
      } else if (this.rideData.deviceInfo && this.rideData.deviceInfo.device) {
        appOs = this.rideData.deviceInfo.device
      }
      return appOs
    },
  },
}
</script>
<style lang="scss" scoped>
.card-body {
    height: 230px;
}
.data-line {
  margin-bottom: 5px;
  .label {
    font-weight: bold;
    font-size: 15px;
    color: #000;
    margin-right: 5px;
  }
  .link {
    cursor: pointer;
  }
}
</style>
